#case-studies-post {
	.section-hero {
		position: relative;
		margin: 50px 0 90px;

		@include mq(mobile) {
			margin-top: 15px;
		}


		.sub-content {
			@extend %container;
		}

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 0 55px;

			@include mq(mobile) {
				margin-bottom: 20px;
			}


			h1 {
				margin: 0;
				font-weight: $font-weight-extrabold;
				text-transform: uppercase;
			}

			a {
				@extend %animation-left;

				@include mq(mobile) {
					display: none;
				}


				.icon {
					height: 23px;
				}

				svg {
					transform: rotate(180deg);

					&:hover {
						color: $color-link-hover;
					}
				}
			}
		}

		.sub-images {
			.slick-track {
				height: 550px !important;

				@include mq(mobile) {
					height: 300px !important;
				}
			}

			.sub-image {
				height: 550px;
				background-position: center;
				background-size: cover;

				@include mq(mobile) {
					height: 300px;
				}
			}

			.slick-dots {
				@extend %container;

				left: 0;
				right: -120px;
				bottom: -40px;
				position: absolute;
				justify-content: flex-end;

				@include mq(mobile) {
					right: 0;
				}


				li {
					@include mq(mobile) {
						flex: 1;

						button {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.section-case-study {
		margin: 0 0 100px;

		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
				max-width: 100%;
			}


			.aside-summary {
				flex-basis: 30%;
				max-width: 380px;
				padding: 40px;
				background: $color-neutral;

				@include mq(mobile) {
					max-width: none;
					order: 1;
					padding: 75px 15px;
				}


				h3 {
					font-weight: $font-weight-extrabold;
					font-size: $font-size-large;
					text-transform: uppercase;
					margin: 0 0 30px;
				}

				ul.list-summary {
					@extend %list-default;

					li {
						margin: 0 0 20px;

						& > ul {
							margin: 20px 0 30px 20px;

							li {
								font-size: $font-size-small;
								font-weight: $font-weight-light;
								margin-bottom: 6px;
							}
						}

						&.mod-comments {
							h5 {
								margin: 0 0 15px;
							}

							p {
								color: $color-primary;
								font-size: $font-size-large;
								font-style: italic;
							}
						}

						h5 {
							margin: 0 0 3px;
							text-transform: uppercase;
							font-size: $font-size-small;
							font-weight: $font-weight-extrabold;
							line-height: $line-height-reset;
						}

						h2, h6, p {
							font-size: $font-size-small;
							font-weight: $font-weight-light;
							margin: 0;

							@include mq(mobile) {
								font-size: $font-size-standard;
							}
						}
					}
				}
			}

			.sub-copy {
				flex-basis: 68%;
				max-width: 740px;

				@include mq(mobile) {
					padding: 0 15px 80px;
				}


				.sub-intro {
					margin: 0 0 60px;
				}

				h4 {
					font-size: $font-size-large;
					text-transform: uppercase;
					font-weight: $font-weight-extrabold;
					margin: 0 0 20px;

					@include mq(mobile) {
						margin-bottom: 15px;
					}
				}

				p {
					font-size: $font-size-standard;
					font-weight: $font-weight-light;
					line-height: $line-height-large;
					margin: 0 0 40px;

					&:last-of-type {
						margin: 0;
					}
				}
			}
		}
	}
}
