#about {
	.section-hero {
		margin: 50px 0 90px;

		@include mq(mobile) {
			margin: 40px 0 80px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			h1 {
				width: 100%;
				font-size: $font-size-h2;
				line-height: $line-height-reset;
				color: $color-secondary;
				text-transform: uppercase;
				font-weight: $font-weight-extrabold;
				margin: 0 0 15px;

				@include mq(mobile) {
					font-size: $font-size-med;
					padding-right: 0px;
				}
			}

			.sub-title {
				max-width: 510px;
				margin: 0 2% 0 0;

				h2 {
					text-transform: uppercase;
					font-weight: $font-weight-extrabold;
					font-size: $font-size-hero-small;
					margin: 0;
					line-height: $line-height-negative;

					@include mq(mobile) {
						font-size: $font-size-hero-semismall;
						margin-bottom: 30px;
						padding-right: 0px;
					}
				}
			}

			.sub-copy {
				max-width: 590px;

				.sub-intro {
					p {
						color: $color-headings;
						margin: 0 0 30px;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: $line-height-small;
					font-weight: $font-weight-light;
					margin: 0;

					&:not(:last-of-type) {
						margin: 0 0 30px;
					}
				}
			}
		}
	}

	.section-approach {
		height: auto;
		position: relative;
		background: $color-headings;

		.sub-image {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			background-position: center;
			background-size: cover;

			@include mq(mobile) {
				height: 300px;
				position: static;
				width: 100%;
			}
		}

		.sub-content {
			@extend %container;

			height: 100%;
			display: flex;
			align-items: center;

			.sub-copy {
				flex-basis: 505px;
				max-width: 48%;
				padding: 55px 15px 70px 0;

				@include mq(mobile) {
					flex-basis: 100%;
					max-width: none;
					padding: 55px 10px 70px;
				}


				h3 {
					color: $color-white;
					font-size: $font-size-h2;
					font-weight: $font-weight-extrabold;

					@include mq(mobile) {
						font-size: $font-size-larger;
					}
				}

				p {
					color: $color-borders-light;
					font-weight: $font-weight-light;
					font-size: $font-size-med;
					line-height: $line-height-small;

					&:last-of-type {
						margin: 0;
					}
				}

				a.btn {
					margin-top: 50px;

					@include mq(mobile) {
						border-width: 1px;
					}
				}
			}
		}
	}

	.section-boa-group {
		margin: 80px 0 70px;

		@include mq(mobile) {
			margin: 80px 0 40px;
		}


		.sub-content {
			@extend %container;

			.sub-intro {
				max-width: 700px;
				text-align: center;
				margin: 0 auto 60px;

				@include mq(mobile) {
					margin-bottom: 55px;
				}


				p {
					font-size: $font-size-med;
					font-weight: $font-weight-regular;
					line-height: $line-height-small;

					&:last-of-type {
						margin: 0;
					}
				}
			}

			ul.list-groups {
				@extend %list-default;

				display: flex;

				@include mq(mobile) {
					flex-direction: column;
				}


				li {
					flex-basis: 31%;
					flex: 1;
					padding: 0 30px;
					text-align: center;

					@include mq(mobile) {
						flex-basis: 100%;
						margin-bottom: 55px;
						max-width: none;
						text-align: center;
					}


					.sub-image {
						margin: 0 0 25px;

						@include mq(mobile) {
							margin-bottom: 5px;
						}


						img {
							display: inline-block;
						}
					}

					p {
						font-size: $font-size-med;
						font-weight: $font-weight-light;
						line-height: $line-height-small;

						&:last-of-type {
							margin: 0;
						}
					}

					.btn {
						margin-top: 30px;
					}
				}
			}
		}
	}
}
