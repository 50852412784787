.section-footer-contact-details {
	position: relative;

	.sub-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: right;
		background-size: cover;
		z-index: -1;

		@include mq(mobile) {
			background-position: 85%;
		}
	}

	.sub-content {
		@extend %container;

		padding: 90px 0 85px;

		@include mq(mobile) {
			padding: 70px 0;

			.sub-copy {
				padding: 0 10px;
			}
		}


		h4 {
			color: $color-white;
			font-size: $font-size-h2;
			font-weight: $font-weight-extrabold;
			margin: 0 0 30px;

			@include mq(mobile) {
				font-size: $font-size-larger;
				margin: 0 0 25px;
			}
		}

		p {
			color: rgba($color-white, 0.75);
			font-size: $font-size-med;
			line-height: $line-height-small;
			font-weight: $font-weight-light;
			max-width: 500px;

			&:last-of-type {
				margin: 0;
			}
		}

		ul.list-contact {
			@extend %list-default;

			display: flex;
			margin: 20px 0 0;

			@include mq(mobile) {
				margin: 40px 0 0;
				flex-direction: column;
			}


			li {
				font-size: $font-size-standard;
				font-weight: $font-weight-bold;
				line-height: $line-height-reset;
				color: rgba($color-white, 0.75);
				text-transform: uppercase;

				a {
					font-size: $font-size-hero-smallest;
					margin-left: 5px;

					&[href^=tel] {
						color: $color-white;
					}
				}

				&:first-child {
					margin-right: 40px;

					@include mq(mobile) {
						margin: 0 0 22px;
					}
				}
			}
		}
	}
}
