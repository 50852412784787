#products-page {
	.section-hero {
		position: relative;

		.sub-images {
			.slick-track {
				height: 550px !important;

				@include mq(mobile) {
					height: 300px !important;
				}
			}

			.sub-image {
				height: 550px;
				background-position: center;
				background-size: cover;

				@include mq(mobile) {
					height: 300px;
				}
			}

			.slick-dots {
				@extend %container;

				left: 0;
				right: 0;
				bottom: -73px;
				position: absolute;
				justify-content: flex-end;

				@include mq(mobile) {
					display: none !important;
				}
			}
		}

		.sub-content {
			@extend %container;

			position: relative;
			height: 100%;

			.sub-copy {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 55px 135px 25px 0;
				background: $color-white;

				@include mq(mobile) {
					padding: 25px 25px 0 5px;
				}


				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: -1000px;
					width: 1000px;
					height: 100%;
					background: $color-white;
				}

				h1 {
					margin: 0;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-size: $font-size-hero-smallish;
					font-weight: $font-weight-extrabold;

					@include mq(mobile) {
						font-size: $font-size-largest;
					}
				}
			}
		}
	}

	.section-content {
		margin: 75px 0;

		@include mq(mobile) {
			margin: 45px 0;
		}


		.sub-content {
			@extend %container;

			.sub-intro,
			.sub-copy {
				max-width: 735px;
			}

			.sub-intro p {
				font-size: $font-size-large;
				color: $color-headings;
				font-weight: $font-weight-regular;
			}

			p {
				font-size: $font-size-med;
				font-weight: $font-weight-light;
				line-height: $line-height-smallish;
				margin: 0 0 40px;

				& + ul {
					margin: 0 0 40px 15px;

					li {
						color: $color-headings;
						font-size: $font-size-med;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.section-case-studies-carousel {
		margin-bottom: 120px;

		@include mq(mobile) {
			margin-top: 100px;
		}
	}
}
