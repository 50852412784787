#case-studies {
	.section-case-studies-list {
		@extend %container;

		margin-bottom: 200px;

		@include mq(mobile) {
			margin-bottom: 80px;
			max-width: none;
		}
	}
}
