#blog-post {
	.section-hero {
		padding: 55px 0 110px;

		@include mq(mobile) {
			padding: 0 0 35px;
		}


		.wrap-hero {
			@extend %container;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			position: relative;

			@include mq(mobile) {
				flex-wrap: wrap;
				padding: 0 5px;
			}


			.sub-copy {
				max-width: 435px;

				@include mq(mobile) {
					max-width: none;
					order: 1;
					padding-top: 60px;
					position: relative;
					width: 100%;
				}


				h3 {
					color: $color-secondary;
					font-size: $font-size-standard;
					font-weight: $font-weight-extrabold;
					line-height: $line-height-reset;
					margin: 0 0 5px;
				}

				h1 {
					margin: 0 0 20px;
					font-weight: $font-weight-extrabold;
					font-size: $font-size-hero-standard-small;
					line-height: $line-height-reset;

					@include mq(mobile) {
						padding-right: 50px;
					}
				}

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: $line-height-small;
					margin: 0;
				}

				.sub-social {
					margin: 40px 0 0;

					@include mq(mobile) {
						position: absolute;
						right: 0;
						top: -40px;
					}


					.sub-date-mob {
						display: none;

						h5 {
							margin: 0 20px 0 0;
							font-size: $font-size-med;
						}

						@include mq(mobile) {
							display: inline-block;
						}
					}

					ul.list-social {
						@extend %list-default;

						width: 25px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;

						@include mq(mobile) {
							padding: 20px 0 0;
						}


						&:hover,
						&:focus {
							width: 100%;
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							.svg-icon {
								transition: color 0.2s ease;
								color: $color-primary;
								cursor: pointer;

								&:hover {
									color: $color-borders;
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 320px;
				width: 610px;
				margin: 0 0 0 auto;

				@include mq(mobile) {
					height: 250px;
					order: 0;
					width: 100%;
				}


				ul.list-image {
					@extend %list-default;

					height: 320px;

					@include mq(mobile) {
						height: 250px;
					}


					li.item-image {
						margin: 0;
						height: 320px;

						@include mq(mobile) {
							height: 250px;
						}


						.sub-background {
							height: 320px;
							background-position: center;
							background-size: cover;

							@include mq(mobile) {
								height: 100%;
								margin-left: -10px;
								width: 100vw;
							}
						}
					}

					.slick-dots {
						bottom: -25px;
						position: absolute;
						right: 0;

						@include mq(mobile) {
							display: none;
						}
					}
				}
			}
		}
	}

	.section-post {
		padding: 0 0 50px;

		@include mq(mobile) {
			padding: 10px 0;
		}


		.sub-content {
			@extend %container;

			max-width: 740px;
			width: 96%;

			.sub-intro {
				margin: 0 0 70px;
			}

			h2, h3 {
				font-size: $font-size-large;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 20px;
			}

			p {
				font-size: $font-size-standard;
				font-weight: $font-weight-light;
				line-height: $line-height-large;
				margin: 0 0 30px;

				&:last-of-type {
					margin: 0;
				}
			}
		}
	}

	.section-related.post-page {
		padding: 60px 0;
		background-color: rgba($color-neutral-dark, 0.2);

		@include mq(mobile) {
			padding: 60px 0 80px;
		}


		.wrap-related {
			@extend %container;
		}

		h2 {
			margin: 0 0 55px;
			font-size: $font-size-h3;

			@include mq(mobile) {
				margin: 0 0 40px;
			}
		}
	}

	.section-blog-list-alt {
		margin: 60px 0 110px;
	}
}
