#processes {
	.section-hero {
		margin: 50px 0 80px;

		@include mq(mobile) {
			margin-bottom: 40px;
		}


		.sub-content {
			@extend %container;

			position: relative;

			h1 {
				font-size: $font-size-h2;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 10px;
				color: $color-secondary;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-med;
				}
			}

			h2 {
				font-size: $font-size-hero-small;
				margin: 0 0 40px;
				line-height: $line-height-reset;
				font-weight: $font-weight-extrabold;
				max-width: 725px;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-hero-semismall;
					padding-right: 0px;
					margin-bottom: 20px;
				}
			}

			.sub-copy-wrap {
				display: flex;
				justify-content: space-between;
				max-width: 730px;

				@include mq(mobile) {
					flex-direction: column;
					padding-right: 20px;
				}


				.sub-copy {
					flex-basis: auto;

					@include mq(mobile) {
						flex-basis: 100%;
					}


					p {
						font-size: $font-size-med;
						line-height: $line-height-small;
						font-weight: $font-weight-light;
					}
				}
			}

			.icon {
				@extend %animation-down;

				bottom: 30px;
				height: 70px;
				overflow: hidden;
				position: absolute;
				right: 35px;
				text-align: center;
				width: 35px;

				svg.grey-arrow-down {
					color: $color-primary;
					height: 70px;
					width: 15px;
				}

				@include mq(mobile) {
					display: none;
				}
			}
		}
	}

	.section-processes {
		background: url("../assets/images/battersea-reach.jpg");
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: 0 0;
		background-size: cover;
		padding-top: 70px;
		margin: -70px 0 235px;

		@include mq(mobile) {
			margin-bottom: 110px;
		}


		.sub-content {
			@extend %container;

			@include mq(mobile) {
				max-width: 100%;
			}


			ul.list-processes {
				@extend %list-default;

				li {
					position: relative;
					margin: 0 0 205px;

					@include mq(mobile) {
						margin-bottom: 65px;
					}


					&:nth-child(2n) {
						.sub-image {
							&:before {
								right: 10%;
								left: auto;
							}

							img {
								margin: 0 0 0 auto;
							}
						}

						.sub-copy {
							right: inherit;
							left: 0;
							padding: 65px 125px 30px 75px;

							@include mq(mobile) {
								padding: 35px 10px;
							}


							svg.grey-arrow-down {
								right: auto;
								left: 31px;

								@include mq(mobile) {
									display: none;
								}
							}
						}
					}

					.sub-image {
						position: relative;

						@include mq(mobile) {
							height: 255px;
							overflow: hidden;
						}


						&:before {
							background: $color-white;
							content: "";
							height: 80px;
							left: 10%;
							position: absolute;
							bottom: -80px;
							width: 90%;
						}

						&.mod-hover img {
							transform: scale(1.05);
						}

						.sub-wrap {
							overflow: hidden;
						}

						img {
							display: block;
							transition: transform .3s ease;

							@include mq(mobile) {
								height: auto;
								width: 100%;
							}
						}
					}

					.sub-copy {
						background: $color-white;
						bottom: 0;
						max-width: 565px;
						padding: 65px 75px 30px 75px;
						position: absolute;
						right: 0;

						@include mq(mobile) {
							max-width: 100%;
							padding: 20px 10px;
							position: static;
						}


						h4 {
							font-size: $font-size-h2;
							color: $color-secondary;
							font-weight: $font-weight-extrabold;
							line-height: $line-height-reset;
							margin: 0 0 5px;

							@include mq(mobile) {
								font-size: $font-size-larger;
							}
						}

						h3 {
							font-size: $font-size-h2;
							text-transform: uppercase;
							font-weight: $font-weight-extrabold;
							line-height: $line-height-reset;
							margin: 0 0 25px;

							@include mq(mobile) {
								font-size: $font-size-h3;
							}
						}

						p {
							font-size: $font-size-med;
							font-weight: $font-weight-light;
							line-height: $line-height-small;
							margin: 0 0 30px;

							@include mq(mobile) {
								padding-right: 10px;
							}
						}

						.btn {
							max-width: 200px;
							padding: 18px;
							text-align: center;
							width: 100%;

							@include mq(mobile) {
								max-width: none;
							}
						}

						.icon {
							@extend %animation-down;

							top: 50%;
							height: 70px;
							overflow: hidden;
							position: absolute;
							right: 35px;
							text-align: center;
							width: 35px;

							@include mq(mobile) {
								display: none;
							}


							&:hover svg {
								color: $color-primary;
							}

							svg {
								color: $color-borders-light;
								height: 70px;
								width: 15px;
							}
						}
					}

					&:nth-child(2n+2) .icon {
						left: 20px;
						right: auto;
					}
				}
			}
		}
	}
}
