.section-blog-list-alt {
	position: relative;
	margin: 150px 0 110px;

	@include mq(mobile) {
		margin-bottom: 65px;
	}


	.sub-background {
		background-image: url("../assets/images/diagonal-panel-new.jpg");
		width: 100%;
		height: 625px;
		background-position: center;
		background-size: cover;
		position: absolute;
		top: -150px;
		left: 0;
		overflow: hidden;

		@include mq(mobile) {
			height: 315px;
			background-position: left;
		}


		&:before, &:after {
			content: "";
			display: block;
			height: 500px;
			position: absolute;
			transform: skewY(9deg);
			width: 100%;
			z-index: 0;
		}

		&:after {
			background: rgba($color-headings, 0.9);
			bottom: -147px;

			@include mq(mobile) {
				background: rgba($color-white, 0.9);
				bottom: 178px;
				clip-path: polygon(100% 100%, 0% 75%, 0% 0%, 100% 0%);
				height: 270px;
				transform: none;
			}
		}

		&:before {
			background: rgba($color-white, 0.9);
			top: -228px;

			@include mq(mobile) {
				background: rgba($color-headings, 0.9);
				clip-path: polygon(100% 100%, 0% 58%, 0% 100%);
				height: 200px;
				height: 200px;
				position: absolute;
				top: -50px;
				transform: none;
				width: 117%;
				z-index: 9;
			}
		}
	}

	.header-title {
		position: relative;
		z-index: 1;
		padding: 180px 0 70px;

		@include mq(mobile) {
			background: rgba($color-headings, 0.9);
			padding: 30px 10px 50px;
			height: 165px;
		}


		.sub-content {
			@extend %container;

			h5 {
				color: $color-secondary;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				font-size: $font-size-large;
				margin: 0 0 10px;
				text-transform: uppercase;
			}

			h4 {
				text-transform: uppercase;
				margin: 0;
				color: $color-white;
				font-size: $font-size-hero-semismall;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;

				@include mq(mobile) {
					font-size: $font-size-hero-standard-small;
				}
			}
		}
	}

	.sub-list {
		@extend %container;

		position: relative;
		z-index: 2;

		@include mq(mobile) {
			padding: 0 8px;
			margin-top: -40px;
		}
	}
}
