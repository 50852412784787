#page {
	h1 {
		margin-bottom: 55px;
	}

	.section-content {
		padding: 60px 0;

		.wrap-content {
			@extend %container;

				.btn {
				max-width: none;
				margin: 20px 0;
				width: auto;
			}

			.cookie-set-message {
				font-size: $font-size-large;
				font-weight: $font-weight-bold;
				margin: 20px 0;
			}

			table {
				border: 1px solid $color-borders-light;
				border-collapse: collapse;

				tr {

					&:first-child {
						td {
							background-color: $color-primary;
							color: $color-white;
						}
					}

					td {
						border: 1px solid $color-borders-light;
						padding: 10px;
					}
				}
			}
		}
	}
}