#case-studies,
#case-studies-map {
	.section-hero {
		margin: 55px 0;

		@include mq(mobile) {
			margin: 40px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			align-items: center;

			@include mq(mobile) {
				align-items: flex-start;
				flex-direction: column;
				padding: 0 5px;
			}


			header {
				display: flex;
				align-items: center;

				@include mq(mobile) {
					margin-bottom: 35px;
					width: 100%;
				}


				h1 {
					font-weight: $font-weight-extrabold;
					margin: 0 35px 0 0;
					line-height: $line-height-reset;
					text-transform: uppercase;

					@include mq(mobile) {
						flex: 1;
					}
				}

				ul.list-actions {
					@extend %list-default;

					display: flex;

					li {
						&.mod-active svg,
						a:hover svg {
							color: $color-text;
						}

						&:first-child {
							margin: 0 30px 0 0;
						}

						.icon-pin {
							color: $color-primary;
						}
					}
				}
			}

			ul.list-categories {
				@extend %list-default;

				display: flex;

				@include mq(mobile) {
					display: block;
					overflow-x: scroll;
					overflow-y: hidden;
					position: relative;
					white-space: nowrap;
					width: 100%;
				}


				li {
					font-weight: $font-weight-semibold;
					font-size: $font-size-small;
					text-transform: uppercase;

					@include mq(mobile) {
						display: inline-block;
					}


					&:not(:last-child) {
						margin: 0 15px 0 0;

						@include mq(mobile) {
							margin-bottom: 10px;
						}
					}

					a {
						color: $color-text;

						@include mq(mobile) {
							font-size: $font-size-standard;
						}
					}

					&.mod-active a,
					a:hover {
						color: $color-borders-light;
					}
				}
			}
		}
	}
}
