.tooltip {
	width: 270px;
	box-shadow: 0 0 9px 3px rgba($color-black,0.25);
	display: block;
	position: relative;
	pointer-events: all;

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-top: 9px solid $color-white;
		bottom: -9px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.sub-image {
		width: 100%;
		height: 100px;
		background-position: center;
		background-size: cover;
	}

	.sub-info {
		background: $color-white;
		padding: 15px 0 15px 20px;

		h4 {
			font-weight: $font-weight-extrabold;
			font-size: $font-size-large;
			line-height: $line-height-reset;
			text-transform: uppercase;
			margin: 0 0 3px;
		}

		h5 {
			margin: 0;
			color: $color-secondary;
			text-transform: uppercase;
			font-size: $font-size-small;
			font-weight: $font-weight-extrabold;
			line-height: $line-height-reset;
		}

		a {
			display: block;
			font-size: $font-size-smallest;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			margin: 10px 0 0;
		}
	}
}

.gm-style {
	.gm-style-iw,
	.gm-style-iw-c {
		overflow: visible;
		position: absolute;
		background: none;
		border-radius: 0;
		padding: 0;
		box-shadow: none;
		border: none;
	}

	.gm-style-iw-t::after {
		content: none;
	}

	.gm-ui-hover-effect {
		display: none !important;
	}
}