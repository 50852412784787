.header-main {
	.sub-logo {
		@include mq(mobile) {
			margin-left: -8px;
		}
	}

	.container {
		@extend %container-large;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px;

		@include mq(mobile) {
			padding: 2px 6px;
		}


		.sub-logo {
			svg {
				width: 140px;
				height: auto;
				display: block;

				@include mq(mobile) {
					width: 90px;
					height: 45px;
				}
			}
		}

		.sub-mobile-button {
			display: none;
			cursor: pointer;
			position: relative;
			width: 25px;
			z-index: 9999;

			@include mq(mobile) {
				display: block;
			}


			.sub-bar {
				width: 100%;
				height: 2px;
				background-color: $color-primary;
				transition: 0.2s;

				&.mod-bar-1 {
					margin: 0 0 9px;
				}
			}

			&.mod-active {
				.mod-bar-1 {
					transform: rotate(-45deg) translate(-2px, 2px);
				}

				.mod-bar-2 {
					transform: rotate(45deg) translate(-6px, -6px);
				}
			}
		}

		.nav-main {
			@include mq(mobile) {
				z-index: 999;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;

				&.mod-active {
					ul.list-nav {
						pointer-events: all;
						padding: 60px 0 0;
						max-height: 100vh;
						height: 100vh;
					}
				}
			}


			ul.list-nav {
				@extend %list-default;

				display: flex;

				@include mq(mobile) {
					flex-direction: column;
					text-align: center;
					background: $color-white;
					max-height: 0;
					overflow: hidden;
					pointer-events: none;
					transition: all 0.3s ease;
				}


				li {
					font-size: $font-size-small;
					text-transform: uppercase;
					font-weight: $font-weight-semibold;
					padding: 20px 0;

					// position: relative;

					@include mq(mobile) {
						font-size: $font-size-hero-standard;
						font-weight: $font-weight-extrabold;
						line-height: $line-height-reset;
						padding: 0;

						span.mod-dropdown {
							display: none;
						}
					}


					a:hover,
					&.active > a,
					&.active > span,
					span:hover {
						color: $color-primary;
					}

					a,
					span {
						color: $color-headings;
						cursor: pointer;
						transition: color 0.2s ease;
					}

					&:not(:last-child) {
						margin-right: 35px;

						@include mq(mobile) {
							margin: 0 0 20px !important;
						}
					}

					&.mod-sub-nav:hover ul.list-sub-nav {
						max-height: 300px;
						overflow: visible;
						pointer-events: all;
						padding: 25px 0;

						@include mq(mobile) {
							padding: 0;
						}
					}
				}

				ul.list-sub-nav {
					@extend %list-default;

					position: absolute;
					width: 215px;
					margin: 15px 0 0 -80px;
					max-height: 0;
					overflow: hidden;
					text-align: center;
					background: $color-white;
					box-shadow: 0 0 12px 0 rgba($color-black, 0.25);
					z-index: 2;
					transition: all 0.3s ease;
					pointer-events: none;

					@include mq(mobile) {
						position: relative;
						max-height: none;
						margin: 0;
						width: auto;
						box-shadow: none;
					}


					&:before {
						content: "";
						border-left: 7px solid transparent;
						border-right: 7px solid transparent;
						border-bottom: 7px solid #fff;
						position: absolute;
						top: -7px;
					}

					li {
						&:not(:last-child) {
							margin: 0 0 10px;
						}

						&:last-child {
							display: none;
						}

						&:nth-last-child(2) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.section-cookies {
	@extend %container;

	align-items: center;
	background: $color-black;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-regular;
				line-height: 22px;
				margin-bottom: 0;

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background .3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}

				&:before {
					display: none;
				}
			}
		}
	}
}