#facilities {
	.section-hero {
		margin: 50px 0 65px;

		@include mq(mobile) {
			margin: 40px 0;
		}


		.sub-content {
			@extend %container;

			h1 {
				color: $color-secondary;
				font-size: $font-size-h2;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 15px;
				text-transform: uppercase;
				width: 100%;

				@include mq(mobile) {
					font-size: $font-size-med;
				}
			}

			h2 {
				margin: 0;
				text-transform: uppercase;
				font-weight: $font-weight-extrabold;
				font-size: $font-size-hero-small;
				line-height: $line-height-negative;

				@include mq(mobile) {
					font-size: $font-size-hero-semismall;
					padding-right: 0px;
				}
			}
		}
	}

	.section-tour {
		max-height: 605px;
		overflow: hidden;
		position: relative;
		transition: max-height .3s ease;

		&.mod-expand {
			max-height: 950px;
		}

		.sub-start-tour {
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			height: 100%;
			left: 0;
			opacity: 1;
			position: absolute;
			top: 0;
			width: 100%;

			.sub-wrapper {
				@extend %container;

				align-self: center;

				.sub-content {
					position: relative;

					h2 {
						color: $color-white;
						font-size: $font-size-hero-small;
						text-transform: uppercase;
						line-height: $line-height-reset;
						font-weight: $font-weight-extrabold;
						margin: 35px 0;

						@include mq(mobile) {
							font-size: $font-size-h3;
						}
					}

					p {
						color: $color-white;
						font-size: $font-size-med;
						font-weight: $font-weight-light;
						line-height: $line-height-small;
						max-width: 500px;

						&:last-of-type {
							margin: 0;
						}
					}

					.btn {
						margin-top: 35px;
					}
				}
			}
		}

		iframe {
			border: 0;
			height: 75vh;
			opacity: 0;
		}
	}

	.section-machines {
		margin: 65px 0 100px;

		@include mq(mobile) {
			margin: 55px 0 0;
		}


		.sub-content {
			@extend %container;

			@include mq(mobile) {
				max-width: 100%;
			}


			.sub-intro {
				text-align: center;
				max-width: 700px;
				margin: 0 auto 70px;

				@include mq(mobile) {
					margin-bottom: 85px;
					padding: 0 15px;
				}


				h3 {
					font-size: $font-size-h2;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-weight: $font-weight-extrabold;
					margin: 35px;

					@include mq(mobile) {
						font-size: $font-size-h3;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: $line-height-small;
					font-weight: $font-weight-light;

					&:last-of-type {
						margin: 0;
					}
				}
			}

			ul.list-machines {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
				}


				li {
					max-width: 390px;
					flex-basis: 32.5%;

					@include mq(mobile) {
						max-width: none;
						width: 100%;
					}


					img {
						display: block;
						width: 100%;
						height: auto;
					}

					.sub-copy {
						margin: 30px 0 65px;

						@include mq(mobile) {
							margin: 30px 15px 65px;
						}


						h4 {
							font-size: $font-size-hero-smallest;
							font-weight: $font-weight-semibold;
							margin: 0 0 15px;
						}

						p {
							font-size: $font-size-med;
							font-weight: $font-weight-light;

							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}

	.section-facilities-map {
		background: #232832;
		padding: 40px 0 80px;

		@include mq(tablet-down) {
			overflow: hidden;
		}


		.sub-desktop {
			display: block;

			@include mq(tablet-down) {
				display: none;
			}
		}

		.sub-mobile {
			display: none;

			@include mq(tablet-down) {
				display: block;
			}
		}

		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			align-items: center;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-map-key {
				max-width: 290px;

				@include mq(tablet-down) {
					margin-top: 70px;
					max-width: none;
					order: 1;
					padding: 0 10px;
					width: 100%;
				}


				h4 {
					text-transform: uppercase;
					font-size: $font-size-hero-standard;
					font-weight: $font-weight-extrabold;
					color: $color-secondary;
					line-height: $line-height-reset;
					margin: 0 0 30px;
				}

				ul.list-key {
					@extend %list-default;

					li {
						&:not(:last-child) {
							margin: 0 0 5px;
						}

						h5 {
							margin: 0;
							font-size: $font-size-standard;
							font-weight: $font-weight-light;
							color: $color-white;

							span {
								display: inline-block;
								color: $color-primary;
								font-weight: $font-weight-bold;
								width: 40px;
							}
						}
					}
				}
			}

			.sub-map {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 530px;
				position: relative;
				min-width: 790px;

				@include mq(tablet-down) {
					height: 430px;
					min-width: 500px;
				}
			}
		}
	}
}
