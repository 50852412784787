#contact {
	.section-hero {
		background-size: cover;
		background-position: right;
		padding: 45px 0;

		@include mq(mobile) {
			background: $color-headings;
			padding: 20px 0;
		}


		.sub-content {
			@extend %container-small;

			display: flex;
			justify-content: space-between;
			align-items: center;

			@include mq(mobile) {
				flex-direction: column;
				text-align: center;
			}


			h1 {
				color: $color-white;
				font-weight: $font-weight-extrabold;
				font-size: $font-size-hero-standard;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-larger;
					margin: 0 0 25px;
				}
			}

			ul.list-contact {
				@extend %list-default;

				display: flex;

				@include mq(mobile) {
					flex-direction: column;
				}


				li {
					font-weight: $font-weight-extrabold;
					text-transform: uppercase;
					color: $color-secondary;

					&:first-child {
						margin: 0 40px 0 0;

						@include mq(mobile) {
							margin: 0 0 15px;
						}
					}

					a {
						display: block;
						font-size: $font-size-hero-smallest;
					}
				}
			}
		}
	}

	.section-form {
		margin: 80px 0 140px;

		@include mq(mobile) {
			margin: 60px 0 100px;
		}


		form {
			@extend %container;

			max-width: 700px;

			@include mq(mobile) {
				text-align: center;
				max-width: none;
				max-width: calc(100% - 10px);
			}


			header {
				margin: 0 0 60px;

				@include mq(mobile) {
					margin: 0 0 35px;
				}


				h2 {
					font-size: $font-size-h5;
					font-weight: $font-weight-extrabold;
					text-transform: uppercase;
					margin: 0;
				}
			}

			input[name="Form_Email"] {
				display: none;
				visibility: hidden;
				opacity: 0;
			}
		}

		.wrap-thanks {
			@extend %container-small;

			max-width: 700px;
			display: none;
		}
	}

	.section-map {
		background: $color-headings;
		position: relative;
		height: 460px;

		@include mq(mobile) {
			height: auto;
		}


		.sub-content {
			@extend %container-small;

			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;

			@include mq(mobile) {
				text-align: center;
				padding: 60px 0;
			}


			h3 {
				color: $color-white;
				font-weight: $font-weight-extrabold;
				font-size: $font-size-hero-smallest;

				@include mq(mobile) {
					margin: 0 0 15px;
				}
			}

			p {
				color: $color-white;
				font-weight: $font-weight-light;
			}

			.btn.btn-primary {
				margin: 20px 0 0;

				@include mq(mobile) {
					margin: 35px 0 0;
				}
			}

			.sub-copy {
				.mod-desktop {
					@include mq(mobile) {
						display: none;
					}
				}

				.mod-mobile {
					display: none;

					@include mq(mobile) {
						display: block;
					}
				}
			}
		}

		#map {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;

			@include mq(mobile) {
				position: relative;
				height: 450px;
				width: 100%;
			}
		}
	}

	.sub-btn-wrap {
		@include mq(mobile) {
			height: 48px;
			max-width: none;
			margin-top: 50px;
			margin-bottom: -30px;
		}
	}
}
