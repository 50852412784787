#processes-page {
	.section-hero {
		background-size: cover;
		background-position: right;
		height: 550px;

		@include mq(mobile) {
			height: 300px;
		}


		.sub-content {
			@extend %container;

			position: relative;
			height: 100%;

			.sub-copy {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 25px 135px 0 0;
				background: $color-white;
				max-width: 400px;

				@include mq(mobile) {
					padding: 20px 10px 0 5px;
					margin-bottom: -50px;
					max-width: 260px;
				}


				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: -1000px;
					width: 1000px;
					height: 100%;
					background: $color-white;
				}

				h1 {
					margin: 0;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-size: $font-size-hero-smallish;
					font-weight: $font-weight-extrabold;

					@include mq(mobile) {
						font-size: $font-size-largest;
						padding-right: 30px;
					}
				}

				h2 {
					line-height: $line-height-reset;
					font-size: $font-size-h2;
					color: $color-secondary;
					font-weight: $font-weight-extrabold;
					margin: 0 0 5px;
				}
			}
		}
	}

	.section-content {
		margin: 80px 0;

		.sub-content {
			@extend %container;

			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				@extend %list-default;

				max-width: 740px;
				margin-right: 1%;

				@include mq(mobile) {
					margin: 0;
					padding: 0 7px;
				}


				.sub-intro p {
					font-size: $font-size-large;
					color: $color-headings;
					font-weight: $font-weight-regular;
				}

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: $line-height-smallish;
					margin: 0 0 40px;
				}
			}

			.aside-next {
				max-width: 230px;
				text-align: right;

				@include mq(mobile) {
					max-width: none;
					text-align: center;
					width: 100%;
				}


				h4 {
					color: $color-secondary;
				}

				h3,
				h4 {
					text-transform: uppercase;
					font-size: $font-size-h1;
					font-weight: $font-weight-extrabold;
					line-height: $line-height-reset;
					margin: 0;

					@include mq(mobile) {
						font-size: $font-size-h2;
					}
				}

				a {
					margin-top: 25px;
					display: block;

					svg:hover {
						color: $color-link-hover;
					}
				}
			}
		}
	}
}
