.section-case-studies-list-alt {
	position: relative;
	margin: 200px 0 110px;

	@include mq(mobile) {
		margin: 200px 0 30px;
	}


	.sub-background {
		background-image: url("../assets/images/diagonal-panel-new.jpg");
		width: 100%;
		height: 625px;
		background-position: 50% 0;
		background-size: cover;
		position: absolute;
		top: -150px;
		left: 0;
		overflow: hidden;

		@include mq(mobile) {
			height: 305px;
		}


		&:before, &:after {
			content: "";
			display: block;
			height: 500px;
			position: absolute;
			transform: skewY(9deg);
			width: 100%;
			z-index: 0;
		}

		&:after {
			background: rgba($color-headings, 0.9);
			bottom: -147px;

			@include mq(mobile) {
				background: rgba($color-white, 0.9);
				bottom: 168px;
				clip-path: polygon(100% 100%, 0% 75%, 0% 0%, 100% 0%);
				height: 270px;
				transform: none;
			}
		}

		&:before {
			background: rgba($color-white, 0.9);
			top: -228px;

			@include mq(mobile) {
				background: rgba($color-headings, 0.9);
				clip-path: polygon(100% 100%, 0% 58%, 0% 100%);
				height: 200px;
				height: 200px;
				position: absolute;
				top: -50px;
				transform: none;
				width: 117%;
				z-index: 9;
			}
		}
	}

	.header-title {
		position: relative;
		z-index: 1;
		padding: 240px 0 60px;

		@include mq(mobile) {
			background: rgba($color-headings, 0.9);
			padding: 30px 10px 50px;
			height: 155px;
		}


		.sub-content {
			@extend %container;

			h4 {
				text-transform: uppercase;
				margin: 0;
				color: $color-white;
				font-size: $font-size-hero-standard-small;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
			}
		}
	}

	.sub-list {
		@extend %container;

		position: relative;
		z-index: 2;

		@include mq(mobile) {
			padding: 0 8px;
			margin-top: -40px;
		}


		ul.list-case-study {
			@extend %list-default;

			text-align: left;

			&.mod-alt {
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
				}


				li {
					flex-basis: 32%;
					max-width: 390px;
					flex-direction: column;
					margin: 0;

					@include mq(mobile) {
						max-width: none;
						margin-bottom: 70px;
					}


					& > a {
						display: block;
						height: 215px;
						margin: 0 0 40px;
						overflow: hidden;
						width: 100%;

						&:hover .sub-image {
							transform: scale(1.2);
						}
					}

					.sub-image {
						margin: 0 0 40px;
						height: 215px;
						width: 100%;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						height: 215px;
						width: 100%;
						transition: transform .3s ease;
						z-index: 999;

						&.mod-hover {
							transform: scale(1.2);
						}
					}

					.sub-details {
						h3 {
							font-size: $font-size-hero-smallest;
							text-transform: uppercase;
							font-weight: $font-weight-extrabold;
							margin: 0;
						}

						h4 {
							font-size: $font-size-standard;
							text-transform: uppercase;
							font-weight: $font-weight-extrabold;
							color: $color-secondary;
							margin: 0 0 20px;
						}

						p {
							font-size: $font-size-standard;
							font-weight: $font-weight-light;

							&:last-of-type {
								margin: 0;
							}
						}

						a {
							@extend %animation-right;

							margin: 25px 0 0;
							text-transform: uppercase;
							display: flex;
							align-items: center;
							font-weight: $font-weight-extrabold;

							.icon {
								overflow: hidden;
								margin-left: 17px;
								max-width: 80px;
								width: 100%;
							}

							&:hover svg {
								color: $color-link-hover;
							}
						}

						.sub-copy {
							line-height: $line-height-large;
						}
					}
				}
			}
		}
	}
}
