#blog {
	.section-hero {
		position: relative;

		.sub-images {
			.slick-track {
				height: 440px !important;

				@include mq(mobile) {
					height: 250px !important;
				}
			}

			.sub-image {
				height: 440px;
				background-position: center;
				background-size: cover;

				@include mq(mobile) {
					height: 250px !important;
				}
			}

			.slick-dots {
				@extend %container;

				left: 0;
				right: 0;
				bottom: -45px;
				position: absolute;
				justify-content: flex-end;
				z-index: 99;

				@include mq(mobile) {
					bottom: 15px;
					justify-content: flex-start;
					z-index: 9;

					li {
						flex: 1;

						button {
							width: 100%;
						}
					}
				}
			}
		}

		.sub-content {
			@extend %container;

			position: relative;
			margin: 0 auto 90px;

			@include mq(mobile) {
				margin-bottom: 20px;
			}


			.sub-copy {
				margin: -150px auto 0 0;
				padding: 45px 135px 0 0;
				background: $color-white;
				max-width: 650px;

				@include mq(mobile) {
					background: $color-white;
					margin: 0;
					max-width: none;
					padding: 45px 5px;
					text-align: center;
					justify-content: center;

					.btn {
						border-width: 1px;
					}
				}


				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: -1000px;
					width: 1000px;
					height: 100%;
					background: $color-white;
				}

				h2 {
					font-size: $font-size-large;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-weight: $font-weight-extrabold;
					margin: 0 0 10px;
					color: $color-secondary;
				}

				ul.list-titles {
					@extend %list-default;

					li {
						margin: 0;
					}

					h3 {
						margin: 0 0 50px;
						text-transform: uppercase;
						line-height: $line-height-reset;
						font-size: $font-size-h1;
						font-weight: $font-weight-extrabold;
					}
				}
			}
		}
	}

	.wrap-list {
		@extend %container;
	}

	.section-list {
		padding: 70px 0;

		@include mq(mobile) {
			padding: 60px 0;
		}


		h1 {
			margin: 0 0 60px;
			font-size: $font-size-h1;
			color: $color-text;

			@include mq(mobile) {
				margin: 0 0 40px;
				font-size: $font-size-h2;
			}
		}

		background: rgba($color-neutral-dark, 0.2);
	}

	ul.list-blog-pages {
		@extend %list-default;

		display: flex;
		justify-content: flex-end;
		margin-right: 20px;

		@include mq(mobile) {
			justify-content: center;
		}


		li {
			height: 40px;
			margin-right: 5px;

			a {
				color: $color-black;
				display: inline-block;
				font-size: $font-size-large;
				padding: 5px;
			}

			&.active a {
				background: $color-primary;
				color: $color-white;
				padding: 5px 15px;
			}

			&.spacer {
				font-size: $font-size-h2;
				line-height: 0.9;
				margin: 0 20px;
			}

			&.next {
				transform: rotate(-90deg);
				margin-left: 20px;
			}

			&.previous {
				transform: rotate(90deg);
				margin-right: 20px;
			}

			&.disabled {
				a {
					pointer-events: none;
				}

				svg.grey-arrow-down {
					color: $color-text;
				}
			}

			svg.grey-arrow-down {
				color: $color-primary;
				width: 25px;
				height: 60px;
			}

			.icon {
				@extend %animation-down;
			}
		}
	}
}
