.section-case-studies-carousel {
	position: relative;

	.sub-images {
		.slick-track {
			height: 620px !important;

			@include mq(mobile) {
				height: 220px !important;
			}
		}

		.sub-image {
			height: 620px;
			background-position: center;
			background-size: cover;

			@include mq(mobile) {
				height: 220px;
			}
		}

		.slick-dots {
			@extend %container;

			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			justify-content: flex-start;
			z-index: 2;

			@include mq(mobile) {
				display: none !important;
			}
		}
	}

	.sub-content {
		@extend %container;

		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0 auto;

		@include mq(mobile) {
			position: relative;
		}


		.sub-copy {
			margin: 0 auto 0 0;
			padding: 80px 125px 60px 0;
			background: $color-white;
			max-width: 650px;

			@include mq(mobile) {
				padding: 40px 5px 0;
				max-width: none;
			}


			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: -1000px;
				width: 1000px;
				height: 100%;
				background: $color-white;
			}

			h4 {
				font-size: $font-size-large;
				text-transform: uppercase;
				line-height: $line-height-reset;
				font-weight: $font-weight-extrabold;
				margin: 0 0 15px;
				color: $color-secondary;
			}

			ul.list-titles {
				@extend %list-default;

				li {
					margin: 0;
				}

				h5 {
					margin: 0 0 5px;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-size: $font-size-hero-semismall;
					font-weight: $font-weight-extrabold;

					@include mq(mobile) {
						font-size: $font-size-hero-standard-small;
					}
				}

				h6 {
					margin: 0 0 45px;
					text-transform: uppercase;
					line-height: $line-height-reset;
					font-size: $font-size-larger;
					color: $color-text;
					font-weight: $font-weight-extrabold;

					@include mq(mobile) {
						font-size: $font-size-large;
						margin: 0 0 35px;
					}
				}

				p {
					color: #52555E;
					font-size: 17px;
					font-weight: 300;
					line-height: 28px;
					margin-bottom: 55px;
				}
			}
		}
	}
}
