#facilities .section-facilities-map .sub-map {
	.sub-map-marker {
		background-image: url("../assets/images/case-studies-map.png");
		width: 52px;
		height: 66px;
		position: absolute;
		cursor: pointer;

		@include mq(tablet-down) {
			background-size: 35px;
			background-repeat: no-repeat;
			height: 44px;
			width: 38px;
		}


		&:hover .sub-info {
			opacity: 1;
		}

		.sub-number {
			text-align: center;
			margin-top: 12px;
			padding-left: 3px;
			color: $color-white;

			@include mq(tablet-down) {
				margin-top: 5px;
				padding-left: 0px;
				width: 37px;
			}
		}

		.sub-info {
			opacity: 0;
			z-index: 2;
			top: -60px;
			left: -82px;
			width: 220px;
			position: absolute;
			pointer-events: none;
			transition: all 0.3s ease;
			box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.25);
			text-align: center;

			&:after {
				content: '';
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-top: 9px solid $color-white;
				position: absolute;
				left: 100px;
			}

			img {
				display: block;
				width: 220px;
				height: auto;
			}

			h4 {
				padding: 15px;
				background: $color-white;
				margin: 0;
				width: 100%;
				text-transform: uppercase;
				font-size: $font-size-standard;
				font-weight: $font-weight-extrabold;
			}
		}
	}
}
