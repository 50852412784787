.footer-main {
	padding: 45px 0 30px;

	@include mq(mobile) {
		padding: 60px 0 40px;
	}


	.section-footer-info {
		@extend %container;

		display: flex;
		justify-content: space-between;
		padding: 0 0 30px;

		@include mq(mobile) {
			padding: 0;
			flex-direction: column-reverse;
		}


		.sub-boa-group,
		.sub-accreditation {
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
				text-align: center;
				margin: 0 0 45px;
			}


			.svg-icon {
				margin-right: 35px;

				@include mq(mobile) {
					margin: 0 auto 5px;
				}
			}

			.sub-copy {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@include mq(mobile) {
					margin: 0 auto;
				}


				h5,
				a {
					margin: 0;
					line-height: $line-height-reset;
					font-weight: $font-weight-medium;
					font-size: $font-size-small;

					@include mq(mobile) {
						line-height: $line-height-large;
					}
				}
			}
		}
	}

	.section-footer-nav {
		@extend %container;

		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@include mq(mobile) {
			flex-direction: column-reverse;
			text-align: center;
		}


		.sub-copyright {
			@include mq(mobile) {
				margin: 15px 0 0;
				text-align: center;
				width: 100%;
			}


			h6 {
				font-weight: $font-weight-light;
				font-size: $font-size-tiny;
				margin: 0;

				@include mq(mobile) {
					font-weight: $font-weight-regular;
				}
			}
		}

		.nav-footer {
			display: flex;
			align-items: flex-end;

			@include mq(mobile) {
				flex-direction: column-reverse;
				text-align: center;
				width: 100%;
			}


			ul.list-nav {
				@extend %list-default;

				display: flex;
				align-content: center;
				justify-content: center;

				@include mq(mobile) {
					width: 100%;
				}


				li {
					font-size: $font-size-small;

					a {
						color: $color-text;

						&:hover {
							color: lighten($color-text, 20%);
						}
					}

					&:not(:last-child):after {
						content: "|";
						margin: 0 15px;

						@include mq(mobile) {
							margin: 0 10px;
						}
					}
				}
			}

			.sub-link {
				margin-left: 85px;

				@include mq(mobile) {
					margin: 0 auto 40px;
				}
			}

			.icon-linkedin-alt {
				@include mq(mobile) {
					transform: scale(1.5);
				}
			}
		}
	}
}
