// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%section-accreditations {
	padding: 105px 0 150px;
	background-size: cover;
	background-position: center;

	@include mq(mobile) {
		padding: 85px 0 50px;
	}


	.sub-content {
		@extend %container;

		h3 {
			text-align: center;
			font-weight: $font-weight-extrabold;
			text-transform: uppercase;
			margin: 0 0 70px;
			font-size: $font-size-h2;

			@include mq(mobile) {
				font-size: $font-size-hero-smallest;
				margin: 0 0 50px;
			}
		}

		ul.list-accreditations {
			@extend %list-default;

			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;
			text-align: center;

			li {
				flex-basis: 20%;

				@include mq(mobile) {
					flex-basis: 32%;
					margin: 0 0 50px;
				}


				&:nth-child(-n+5) {
					margin: 0 0 65px;

					@include mq(mobile) {
						margin: 0 0 50px;
					}
				}

				img {
					display: block;
					margin: 0 auto;

					@include mq(mobile) {
						width: 100%;
						height: auto;
						max-width: 80px;
					}
				}
			}
		}
	}
}

%section-panels {
	ul.list-panel {
		@extend %list-default;

		& > li {
			height: 600px;
			position: relative;

			@include mq(mobile) {
				height: auto;
			}


			&:nth-child(2n+1) {
				background: $color-headings;

				.sub-image {
					left: 0;
					right: inherit;
				}

				.sub-content {
					justify-content: flex-end;

					.sub-copy {
						h4 {
							color: $color-white;

							@include mq(mobile) {
								font-size: $font-size-larger;
							}
						}

						p {
							color: $color-borders-light;
						}
					}
				}
			}

			.sub-image {
				position: absolute;
				top: 0;
				right: 0;
				width: 50%;
				height: 100%;
				background-position: center;
				background-size: cover;

				@include mq(mobile) {
					position: relative;
					height: 255px;
					width: 100%;
				}
			}

			.sub-content {
				@extend %container;

				height: 100%;
				display: flex;
				align-items: center;

				.sub-copy {
					flex-basis: 505px;
					max-width: 48%;

					@include mq(mobile) {
						flex-basis: 100%;
						max-width: 100%;

						@include mq(mobile) {
							padding: 0 0 80px;
						}
					}


					h4 {
						font-size: $font-size-h2;
						font-weight: $font-weight-extrabold;
						text-transform: uppercase;

						@include mq(mobile) {
							margin: 45px 0 25px;
						}
					}

					p {
						font-weight: $font-weight-light;
						font-size: $font-size-med;
						line-height: $line-height-small;

						@include mq(mobile) {
							line-height: $line-height-large;
						}


						&:last-of-type {
							margin: 0;
						}
					}

					a.btn {
						margin-top: 50px;
					}
				}
			}

			.list-policies {
				@extend %list-default;

				& > li {
					font-size: $font-size-med;
					margin: 0 0 15px;
				}
			}
		}
	}
}

// Animations
%animation-down {
	overflow: hidden;

	&:hover svg {
		animation: arrow-anim-down 1s;
		color: $color-primary;
	}
}

%animation-right {
	overflow: hidden;

	&:hover svg {
		animation: arrow-anim-right 1s;
		color: $color-primary;
	}
}

%animation-left {
	overflow: hidden;

	&:hover svg {
		animation: arrow-anim-left 1s;
		color: $color-primary;
	}
}
