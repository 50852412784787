.svg-icon {
	color: #ff99f8; // Default colour to stand out
	display: inline-block;
	vertical-align: middle;

	// Logos
	&.logo-boa-group {
		width: 122px;
		height: 51px;
	}

	// Icons

	&.icon-cert {
		height: 49px;
		width: 37px;
		color: $color-headings;
	}

	&.icon-arrow {
		width: 52px;
		height: 13px;
		color: $color-primary;
	}

	&.icon-logo {
		height: 48px;
	}

	&.icon-at {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-phone {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-pin {
		height: 27.6px;
		width: 20px;
		color: $color-text;
	}

	&.icon-list {
		height: 20px;
		width: 22px;
		color: $color-text;
	}

	// Social

	&.icon-social {
		height: 25px;
		width: 24px;
		color: $color-text;
	}

	&.icon-twitter {
		height: 24px;
		width: 34px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin {
		height: 23px;
		width: 24px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin-alt {
		height: 24px;
		width: 24px;
		color: $color-secondary;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-facebook {
		height: 26px;
		width: 13px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-pinterest {
		height: 27px;
		width: 21px;
		color: $color-neutral;

		&:hover {
			color: $color-primary;
		}
	}
}
