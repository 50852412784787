.section-case-studies-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include mq(mobile) {
		display: block;
	}


	& > div {
		height: 450px;

		@include mq(mobile) {
			height: 250px;
			margin-bottom: 6px;
		}


		&:nth-child(3n+1) {
			width: 100%;
			margin: 6px 0;

			@include mq(mobile) {
				margin-top: 0;
			}
		}

		&:nth-child(3n+2) {
			width: calc(50% - 3px);

			@include mq(mobile) {
				width: 100%;
			}
		}

		&:nth-child(3n+3) {
			width: calc(50% - 3px);

			@include mq(mobile) {
				width: 100%;
			}
		}

		&.flex-wrap-column {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include mq(mobile) {
				height: auto;
				margin-bottom: 0;
			}


			& > div {
				height: calc(50% - 3px);

				@include mq(mobile) {
					height: 250px;
					margin-bottom: 6px;
				}
			}
		}
	}

	.sub-case-study {
		display: flex;
		align-items: flex-end;
		background-size: cover;
		background-position: center;
		position: relative;

		a.sub-link {
			padding: 20px 35px 20px 20px;
			background: $color-white;
			transition: background 0.3s ease;
			display: flex;
			align-items: flex-start;
			width: auto;

			&:hover {
				background: $color-headings;

				h3 {
					color: $color-white;
				}

				.svg-icon {
					width: 68px;
					opacity: 1;
				}
			}

			&.mod-mobile {
				display: none;

				@include mq(mobile) {
					background: transparent;
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0%;
					width: 100%;
				}
			}

			h3 {
				transition: color 0.3s ease;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				text-transform: uppercase;
				margin: 0 0 5px;
			}

			h4 {
				color: $color-secondary;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				text-transform: uppercase;
				margin: 0;
			}

			.svg-icon {
				margin-top: 5px;
				padding-left: 15px;
				transition: all 0.3s ease;
				width: 0;
				opacity: 0;
			}
		}
	}
}
