ul.blog-list-posts {
	@extend %list-default;

	&.mod-alt {
		display: flex;
		justify-content: space-between;

		@include mq(mobile) {
			flex-direction: column;
		}


		li {
			flex-basis: 32%;
			max-width: 390px;
			flex-direction: column;
			margin: 0;

			@include mq(mobile) {
				flex-basis: 100%;
				max-width: none;
				margin: 0 0 55px;
			}


			& > a {
				display: block;
				height: 215px;
				overflow: hidden;
				margin: 0 0 40px;
				width: 100%;

				&:hover .sub-image {
					transform: scale(1.2);
				}
			}

			.sub-image {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				flex-basis: auto;
				height: 215px;
				width: 100%;
				z-index: 999;
				transition: transform .3s ease;

				&.mod-hover {
					transform: scale(1.2);
				}

				@include mq(mobile) {
					margin: 0 0 30px;
				}
			}

			.sub-details {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				width: 100%;

				.sub-copy {
					flex-grow: 1;
				}

				h3 {
					font-size: $font-size-hero-smallest;
					font-weight: $font-weight-extrabold;
					margin: 0 0 20px;
					min-height: 55px;
					text-transform: uppercase;

					@include mq(mobile) {
						font-size: $font-size-larger;
						line-height: 1.25;
						min-height: auto;
					}
				}

				p {
					font-size: $font-size-standard;
					font-weight: $font-weight-light;
					line-height: $line-height-large;
					margin: 0;

					&:last-of-type {
						margin: 0;
					}
				}

				a {
					@extend %animation-right;

					margin: 25px 0 0;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					font-weight: $font-weight-extrabold;

					.icon {
						overflow: hidden;
						margin-left: 17px;
						max-width: 80px;
						width: 100%;
					}

					svg {
						margin: 0;
					}
				}
			}
		}
	}

	li {
		margin: 0 0 55px;
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: normal;

		@include mq(mobile) {
			flex-direction: column;
			margin: 0 0 25px;
			width: 100%;
		}


		.sub-image {
			max-width: 600px;
			background-color: grey;
			width: 600px;
			height: 340px;
			background-size: cover;

			@include mq(mobile) {
				height: 200px;
				max-width: none;
				padding: 0 8px;
				width: 100%;
			}


			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}

		.sub-detail {
			display: flex;
			flex-basis: 48%;
			flex-direction: column;
			max-width: 565px;
			padding-right: 180px;
			position: relative;

			@include mq(mobile) {
				max-width: none;
				padding: 35px 15px;
			}


			.sub-category {
				display: block;
				margin: 25px 0 15px 0;
				font-weight: $font-weight-bold;
				color: $color-secondary;
				text-transform: uppercase;
			}

			h3 {
				font-size: $font-size-h1;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 20px;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-larger;
				}
			}

			.sub-copy {
				margin: 0 0 40px;

				p {
					line-height: $line-height-small;
					font-size: $font-size-med;
					font-weight: $font-weight-light;

					&:last-child {
						margin: 0;
					}
				}
			}

			.btn {
				max-width: 200px;
				text-align: center;

				@include mq(mobile) {
					max-width: none;
					width: 100%;
				}
			}

			.sub-date {
				color: $color-secondary;
				font-size: $font-size-standard;
				font-weight: $font-weight-extrabold;
				margin: 0;
			}
		}
	}
}
