#boa-academy {
	.section-hero {
		margin: 50px 0 90px;

		@include mq(mobile) {
			margin: 40px 0 80px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			h1 {
				width: 100%;
				font-size: $font-size-h2;
				line-height: $line-height-reset;
				color: $color-secondary;
				text-transform: uppercase;
				font-weight: $font-weight-extrabold;
				margin: 0 0 15px;

				@include mq(mobile) {
					font-size: $font-size-med;
					padding-right: 0px;
				}
			}

			.sub-title {
				max-width: 510px;
				margin: 0 2% 0 0;

				h2 {
					text-transform: uppercase;
					font-weight: $font-weight-extrabold;
					font-size: $font-size-hero-small;
					margin: 0;
					line-height: $line-height-negative;

					@include mq(mobile) {
						font-size: $font-size-hero-semismall;
						margin-bottom: 30px;
						padding-right: 0px;
					}
				}
			}

			.sub-copy {
				max-width: 590px;

				.sub-intro {
					p {
						color: $color-headings;
						margin: 0 0 30px;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: $line-height-small;
					font-weight: $font-weight-light;
					margin: 0;

					&:not(:last-of-type) {
						margin: 0 0 30px;
					}
				}
			}
		}
	}

	.section-accreditations {
		@extend %section-accreditations;
	}

	.section-panel {
		@extend %section-panels;
	}
}
