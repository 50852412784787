#case-studies-map {
	.section-map {
		@extend %container;

		margin: 0 auto 145px;

		@include mq(mobile) {
			margin-bottom: 0;
		}


		#map {
			height: 610px;
			width: 100%;
		}
	}
}
