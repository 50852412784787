#home {
	.section-hero {
		position: relative;
		height: 605px;

		@include mq(mobile) {
			height: 350px;
		}


		.sub-desktop {
			@include mq(mobile) {
				display: none;
			}
		}

		.sub-mobile {
			display: none;

			@include mq(mobile) {
				display: block;
			}
		}

		.sub-background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: -1;
		}

		.sub-content {
			@extend %container;

			display: flex;
			align-items: center;
			height: 100%;

			@include mq(mobile) {}


			.sub-copy {
				max-width: 605px;

				@include mq(mobile) {
					max-width: 360px;
					text-align: center;
					margin: 0 auto;
				}


				h3 {
					@include mq(mobile) {
						display: none;
					}
				}

				h1 {
					font-weight: $font-weight-extrabold;
					font-size: $font-size-hero-med;
					line-height: $line-height-reset;
					text-transform: uppercase;
					margin: 0 0 20px;

					@include mq(mobile) {
						margin: 0 0 35px;
						font-size: $font-size-hero-standard-small;
					}


					span {
						font-size: $font-size-hero-large;
						color: $color-white;

						@include mq(mobile) {
							font-size: $font-size-hero-small;
						}
					}
				}

				p {
					color: $color-headings;
					font-size: $font-size-larger;
					margin-bottom: 50px;

					@include mq(mobile) {
						display: none;
					}
				}

				.btn.btn-primary {
					margin: 0 0 45px;

					&:before {
						background: $color-white;
					}

					&:hover span {
						color: $color-black;
					}

					@include mq(mobile) {
						margin: 0;
					}
				}
			}
		}
	}

	.section-intro-boxes {
		margin: 6px 0 140px;

		@include mq(mobile) {
			margin: 6px 0	50px;
		}


		.sub-images {
			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				display: none;
			}


			.sub-image {
				flex-basis: calc(50% - 3px);
				background-position: center;
				background-size: cover;
				height: 19.31vw;
			}
		}

		.sub-content {
			@extend %container;

			@include mq(mobile) {
				max-width: none;
			}


			ul.list-boxes {
				@extend %list-default;

				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
				}


				li {
					max-width: 47%;

					@include mq(mobile) {
						max-width: none;
						margin: 0 0 45px;

						&:last-child {
							margin-bottom: 0;
						}
					}


					.sub-image {
						display: none;
						width: 100%;

						@include mq(mobile) {
							display: block;
						}


						img {
							width: 100%;
							height: auto;
						}
					}

					h3 {
						font-size: $font-size-h1;
						font-weight: $font-weight-extrabold;
						text-transform: uppercase;
						margin: 0;
						padding: 30px 35px;
						background: $color-white;
						margin: -30px 0 0 0;
						display: inline-block;

						@include mq(mobile) {
							font-size: $font-size-hero-standard;
							margin: 0;
							padding: 29px 20px 20px;
						}
					}

					.sub-copy {
						padding-left: 35px;

						@include mq(mobile) {
							padding: 0 20px;
						}


						p {
							font-size: $font-size-med;
							line-height: $line-height-small;
							font-weight: $font-weight-light;
							margin: 0 0 30px;

							@include mq(mobile) {
								margin: 0 0 35px;
							}
						}
					}
				}
			}
		}
	}

	.section-overview-box {
		position: relative;
		height: 695px;
		margin: 0 0 75px;

		@include mq(mobile) {
			height: auto;
		}


		.sub-background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: right;
			background-size: cover;
			z-index: -1;

			@include mq(mobile) {
				position: relative;
				display: block;
				height: 350px;
			}
		}

		.sub-content {
			@extend %container;

			display: flex;
			align-items: center;
			height: 100%;

			@include mq(mobile) {
				margin-top: -20px;
			}


			.sub-copy {
				max-width: 515px;

				@include mq(mobile) {
					max-width: none;
				}


				h4 {
					font-size: $font-size-large;
					color: $color-secondary;
					font-weight: $font-weight-extrabold;
					text-transform: uppercase;
					margin: 0 0 20px;

					@include mq(mobile) {
						margin: 0 0 10px;
					}
				}

				h3 {
					text-transform: uppercase;
					font-size: $font-size-hero-small;
					font-weight: $font-weight-extrabold;
					line-height: $line-height-reset;
					margin: 0 0 40px;

					@include mq(mobile) {
						font-size: $font-size-h1;
						margin-bottom: 20px;
					}
				}

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: $line-height-smallish;
					margin: 0 0 35px;

					@include mq(mobile) {
						margin: 0 0 45px;
					}
				}

				.btn.btn-primary {
					margin: 0 0 30px;

					@include mq(mobile) {
						margin: 0;
					}
				}
			}
		}
	}

	.section-blog-list-alt {
		margin: 170px 0 60px;
	}
}
