@keyframes arrow-anim-down {
	from {
		transform: translateY(0%);
	}

	40% {
		transform: translateY(150%);
	}

	50% {
		opacity: 0;
		transform: translateY(-90%);
	}

	70% {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes arrow-anim-right {
	from {
		transform: translateX(0%);
	}

	40% {
		transform: translateX(150%);
	}

	50% {
		opacity: 0;
		transform: translateX(-90%);
	}

	70% {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes arrow-anim-left {
	from {
		margin-left: 0%;
	}

	40% {
		margin-left: -150%;
	}

	50% {
		opacity: 0;
		margin-left: 100%;
	}

	70% {
		opacity: 1;
		margin-left: 0%;
	}
}
