#careers-page {

	.section-hero {
		background-size: cover;
		background-position: right;

		@include mq(mobile) {
			background: none !important;
		}

		.sub-content {
			padding: 65px 0 70px;
			@extend %container;
			justify-content: space-between;
			align-items: center;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
				padding: 40px 0 55px;
				align-items: flex-start;

				a.btn {
					display: none;
				}
			}

			h1 {
				color: $color-white;
				font-weight: $font-weight-extrabold;
				text-transform: uppercase;
				line-height: $line-height-reset;
				max-width: 0 0 25px;

				@include mq(mobile) {
					height: $font-size-larger;
					color: $color-headings;
				}
			}

			ul.list-details {
				@extend %list-default;

				li {
					font-size: $font-size-med;
					color: $color-secondary;
					font-weight: $font-weight-light;

					@include mq(mobile) {
						color: $color-text;
					}

					&:first-child {
						margin: 0 0 5px;
					}

					strong {
						text-transform: uppercase;
					}
				}
			}

		}
	}

	.section-details {
		margin: 80px 0;

		@include mq(mobile) {
			margin: 0 0 40px;
		}

		.sub-content {
			@extend %container;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			@include mq(mobile) {
				max-width: 100%;
				flex-direction: column;
			}

			ul.list-job-details {
				@extend %list-default;
				max-width: 740px;
				margin-right: 1%;

				@include mq(mobile) {
					max-width: $container-max-width-mobile;
					margin: 0 auto;
				}

				li {
					margin: 0 0 20px;

					@include mq(mobile) {
						margin: 0 0 10px;
					}
				}

				h3 {
					font-size: $font-size-med;
					font-weight: $font-weight-extrabold;
					line-height: $line-height-reset;
				}

				p {
					line-height: $line-height-large;
					font-weight: $font-weight-light;
				}

				 ul {

					> li {
						list-style-type: disc;
						margin: 0 0 20px;

						@include mq(mobile) {
							margin: 0 0 10px;
						}
					}
				}
			}

			.aside-apply {
				padding: 40px 25px 20px 45px;
				background: $color-neutral;
				max-width: 380px;

				@include mq(mobile) {
					max-width: 100%;
					padding: 45px 0 30px;

					.sub-copy {
						max-width: $container-max-width-mobile;
						margin: 0 auto;
					}
				}

				h4 {
					font-weight: $font-weight-extrabold;
					text-transform: uppercase;
				}

				p {
					line-height: $line-height-large;
					font-weight: $font-weight-light;

					&.mod-send-to {
						font-size: $font-size-med;
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
}