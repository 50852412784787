#accreditations {
	.section-hero {
		margin: 50px 0 90px;

		@include mq(mobile) {
			margin: 40px 0 80px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include mq(mobile) {
				flex-direction: column;
			}


			h1 {
				width: 100%;
				font-size: $font-size-h2;
				line-height: $line-height-reset;
				color: $color-secondary;
				text-transform: uppercase;
				font-weight: $font-weight-extrabold;
				margin: 0 0 15px;

				@include mq(mobile) {
					font-size: $font-size-hero-smallest;
					margin: 0 0 15px;
				}
			}

			.sub-title {
				max-width: 800px;
				margin: 0 0 30px 0;
				width: 100%;

				h2 {
					text-transform: uppercase;
					font-weight: $font-weight-extrabold;
					font-size: $font-size-hero-small;
					margin: 0;
					line-height: $line-height-negative;

					@include mq(mobile) {
						font-size: $font-size-hero-semismall;
						margin: 0 0 30px;
					}
				}
			}

			.sub-copy {
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
				}


				.sub-intro {
					flex: 1;
					margin-right: 5%;

					@include mq(mobile) {
						margin-right: 0;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: $line-height-small;
					font-weight: $font-weight-light;
					margin: 0;

					@include mq(mobile) {
						line-height: $line-height-large;
					}


					&:not(:last-of-type) {
						margin: 0 0 30px;
					}
				}

				.sub-main {
					flex: 1;
				}
			}
		}
	}

	.section-accreditations {
		@extend %section-accreditations;
	}

	.section-panel {
		@extend %section-panels;
	}
}
