ul {
	margin: 0 0 10px 14px;
	padding: 0;

	li {
		margin: 0 0 0 14px;
		padding: 0;
	}
}

ul.slick-dots {
	@extend %list-default;

	display: flex;

	li {
		&.slick-active button {
			background: $color-primary;
		}

		button {
			width: 65px;
			height: 2px;
			padding: 0;
			border: none;
			background: $color-borders-light;
			text-indent: -9999px;
			outline: none;
			cursor: pointer;
			display: block;
			transition: background .3s ease;

			&:hover {
				background: $color-primary;
			}
		}
	}
}
