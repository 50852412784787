/* LINKS */

a {
	color: $color-link;
	text-decoration: none;

	&:active {
		background-color: transparent;
	}

	&:hover {
		color: $color-link-hover;
	}

	&:active,
	&:focus {
		color: $color-link-active;
		border: 0 none;
		outline: 0 none;
	}
}

/* GENERAL BUTTONS */

.btn {
	@extend %nofocus;

	@extend %noselect;

	border: none;
	outline: none;
	cursor: pointer;

	@include mq(mobile) {
		border-width: 2px;
		padding: 16px 45px;
	}


	span {
		position: relative;
		display: block;
		transition: color .5s ease;
		z-index: 99;
	}

	&:before {
		background: $color-white;
		content: "";
		height: 100%;
		left: -135%;
		position: absolute;
		top: 0;
		transform: skew(-45deg);
		transition: left .5s ease;
		width: 123%;
	}

	&:hover {
		&:before {
			left: -11%;
			width: 125%;
		}

		span {
			color: $color-black;
		}
	}

	&.btn-primary {
		padding: $padding-button;
		display: inline-block;
		line-height: 1;
		font-size: $text-size-button;
		background: transparent;
		color: $color-primary;
		border-radius: $radius-button;
		transition: all 0.2s ease;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: $font-weight-bold;
		position: relative;
		overflow: hidden;

		&:before {
			background: $color-primary;
		}

		&:hover span {
			color: $color-white;
		}

		// IE11 Rendering Bug Fix
		border: 1.5px solid $color-buttons;

		&:focus {
			background: $color-primary;
			color: $color-white;
		}

		&.mod-alt {
			color: $color-white;
			border: 1.5px solid $color-white;

			&:focus {
				color: $color-primary;
			}
		}

		&:disabled, &[disabled] {
			background: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
				background: highlight-by($color-buttons, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			background: transparent;
			color: $color-buttons;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons;
				background: transparent;
				box-shadow: none;
			}

			@include mq(mobile) {
				padding: 0;
				display: inline-block;
				text-align: unset;
			}
		}

		@include mq(mobile) {
			display: block;
			text-align: center;
			padding: 16px 45px;
			border-width: 2px;
		}
	}

	&.btn-icon {
		padding: 12px;
		border: none;
		display: inline-block;
		font-size: $font-size-med;
		background: none;
	}
}

.sub-btn-wrap {
	display: block;
	height: 42px;
	max-width: 195px;
	overflow: hidden;
	position: relative;

	&:before {
		background: $color-primary;
		content: "";
		height: 100%;
		left: -135%;
		position: absolute;
		top: 0;
		transform: skew(-45deg);
		transition: left .5s ease;
		width: 123%;
	}

	&:hover {
		&:before {
			left: -11%;
			width: 125%;
		}

		.btn-submit, .btn-primary, .btn {
			color: $color-white;
		}
	}

	.btn-submit {
		display: block;
		margin-top: 0;
		z-index: 999;
	}
}
