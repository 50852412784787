#careers {
	.section-hero {
		margin: 55px 0;

		@include mq(mobile) {
			margin: 35px 0 70px;

			a.btn.btn-primary {
				display: none;
			}
		}


		.sub-content {
			@extend %container;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}


			header {
				max-width: 450px;

				@include mq(mobile) {
					max-width: 85%;
				}


				h1 {
					font-size: $font-size-hero-small;
					font-weight: $font-weight-extrabold;
					line-height: $line-height-reset;
					margin-bottom: 50px;

					@include mq(mobile) {
						font-size: $font-size-hero-semismall;
						margin: 0 0 30px;
					}
				}
			}

			.sub-copy {
				max-width: 590px;

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: $line-height-small;

					@include mq(mobile) {
						line-height: $line-height-large;
					}
				}
			}
		}

		a.btn.btn-primary.mod-mobile {
			display: none;

			@include mq(mobile) {
				margin: 35px 0 0;
				display: block;
			}
		}
	}

	.section-content-boxes {
		@include mq(mobile) {
			margin: 0 0 40px;
		}


		ul.list-boxes {
			@extend %list-default;

			li {
				position: relative;
				height: 600px;

				@include mq(mobile) {
					height: auto;
				}


				&:nth-child(2n+1) {
					background: $color-headings;

					.sub-content {
						justify-content: flex-start;

						h3 {
							color: $color-white;
						}

						p {
							color: rgba($color-white, 0.4);
						}
					}

					.sub-image {
						left: inherit;
						right: 0;
					}
				}

				.sub-image {
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					background-position: center;
					background-size: cover;

					@include mq(mobile) {
						position: relative;
						width: 100%;
						height: 255px;
					}
				}

				.sub-content {
					display: flex;

					@extend %container;

					height: 100%;
					align-items: center;
					justify-content: flex-end;

					@include mq(mobile) {
						padding: 60px 0;
					}


					.sub-copy {
						position: relative;
						max-width: 505px;
						width: 45%;

						@include mq(mobile) {
							max-width: none;
							width: 100%;
						}


						.boa-academy {
							position: absolute;
							bottom: 0;
							right: 0;

							@include mq(mobile) {
								position: relative;
								margin-top: 40px;
							}
						}

						&.mod-no-btn {
							.boa-academy {
								bottom: -80px;

								@include mq(mobile) {
									bottom: 0;
								}
							}
						}
					}

					h3 {
						font-weight: $font-weight-extrabold;
						font-size: $font-size-h2;
						margin: 0 0 20px;

						@include mq(mobile) {
							font-size: $font-size-h3;
						}
					}

					p {
						font-size: $font-size-med;
						font-weight: $font-weight-light;

						@include mq(mobile) {
							line-height: $line-height-large;
						}


						&:last-of-type {
							margin: 0;
						}
					}

					.btn {
						margin: 60px 0 0;

						@include mq(mobile) {
							margin: 40px 0 0;
						}
					}
				}
			}
		}
	}

	.section-vacancies {
		padding: 140px 0 0;
		background: linear-gradient(180deg, #F5F8F8 0%, rgba(245, 248, 248, 0) 100%);

		@include mq(mobile) {
			padding: 85px 0 0;
		}


		.sub-content {
			@extend %container;

			header {
				text-align: center;
				max-width: 800px;
				margin: 0 auto 90px;

				@include mq(mobile) {
					margin: 0 auto 70px;
					text-align: left;
				}


				h2 {
					margin: 0 0 20px;
					font-weight: $font-weight-extrabold;
					text-transform: uppercase;

					@include mq(mobile) {
						font-size: $font-size-larger;
						margin: 0 0 15px;
					}
				}

				p {
					margin: 0;
					line-height: $line-height-small;
					font-size: $font-size-med;

					@include mq(mobile) {
						font-weight: $font-weight-light;
						line-height: $line-height-large;
					}
				}
			}

			ul.list-vacancies {
				@extend %list-default;

				li.item-vacancy {
					&:after {
						display: block;
						content: '';
						margin: 65px 0;
						width: 100%;
						height: 1px;
						background: $color-borders-lighter;

						@include mq(mobile) {
							margin: 60px 0;
						}
					}

					.sub-title {
						h4 {
							font-size: $font-size-med;
							text-transform: uppercase;
							font-weight: $font-weight-bold;

							@include mq(mobile) {
								margin: 0 0 15px;
							}
						}
					}

					.sub-info {
						display: flex;
						justify-content: space-between;
						max-width: 1090px;

						@include mq(mobile) {
							flex-direction: column-reverse;
						}


						.sub-intro {
							max-width: 790px;

							p {
								line-height: $line-height-large;
								font-weight: $font-weight-light;
								margin: 0;
							}
						}

						ul.list-details {
							@extend %list-default;

							@include mq(mobile) {
								display: flex;
								margin: 0 0 15px;
							}


							li:first-child {
								margin: 0 0 10px;

								@include mq(mobile) {
									margin: 0 30px 0 0;
								}
							}

							strong {
								font-weight: $font-weight-bold;
								text-transform: uppercase;
							}
						}
					}

					.btn {
						margin: 35px 0 0;
						max-width: 200px;
						width: 100%;
						text-align: center;

						@include mq(mobile) {
							margin: 40px 0 0;
							max-width: none;
						}
					}
				}
			}
		}
	}

	.section-register {
		@extend %container;

		padding: 10px 0 100px;
		text-align: center;

		@include mq(mobile) {
			padding: 0 0 40px;
		}


		.btn-close {
			display: none;
			font-size: $font-size-h1;
		}

		.sub-register {
			display: none;

			form {
				input:not(.btn-submit) {
					padding: 0;
					text-align: center;
					width: 70%;

					@include placeholder {
						color: $color-borders;
					}
				}

				.sub-btn-wrap {
					margin: 0 auto;
				}
			}
		}
	}
}
