#products {
	.section-hero {
		margin: 50px 0 80px;

		@include mq(mobile) {
			margin: 40px 0;
		}


		.sub-content {
			@extend %container;

			h1 {
				color: $color-secondary;
				font-size: $font-size-h2;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 10px;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-med;
				}
			}

			h2 {
				font-size: $font-size-hero-small;
				font-weight: $font-weight-extrabold;
				line-height: $line-height-reset;
				margin: 0 0 40px;
				max-width: 760px;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-hero-semismall;
					padding-right: 0px;
					margin-bottom: 30px;
				}
			}

			.sub-copy-wrap {
				display: flex;
				justify-content: space-between;
				max-width: 725px;

				@include mq(mobile) {
					flex-direction: column;
					padding-right: 20px;
				}


				.sub-copy {
					flex-basis: auto;

					@include mq(mobile) {
						flex-basis: 100%;
					}


					p {
						font-size: $font-size-med;
						line-height: $line-height-small;
						font-weight: $font-weight-light;

						&:only-child {
							@include mq(mobile) {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.section-products {
		margin: 0 0 80px;

		.sub-content {
			@extend %container;

			@include mq(mobile) {
				max-width: 100%;
			}


			ul.list-products {
				@extend %list-default;

				display: grid;
				grid-template-columns: repeat(2, 50%);
				grid-template-rows: repeat(6, 225px);

				@include mq(mobile) {
					display: block;
				}


				li {
					align-items: flex-end;
					background-position: center;
					background-size: cover;
					display: flex;
					grid-column: 3 /1;
					margin-bottom: 6px;
					min-height: 444px;
					position: relative;

					@include mq(mobile) {
						min-height: 220px;
					}


					&:nth-child(1) {
						grid-column: 1 / 3;
						grid-row: 1 / 3;
						margin: 0 0 6px;
					}

					&:nth-child(2) {
						grid-column: 1;
						grid-row: 3;
						margin: 0 6px 6px 0;
						min-height: auto;

						@include mq(mobile) {
							margin-right: 0;
							min-height: 220px;
						}
					}

					&:nth-child(3) {
						grid-column: 1;
						grid-row: 4;
						margin: 0 6px 6px 0;
						min-height: auto;

						@include mq(mobile) {
							margin-right: 0;
							min-height: 220px;
						}
					}

					&:nth-child(4) {
						grid-column: 2;
						grid-row: 3 / 5;
						margin: 0 0 6px;
					}

					&:nth-child(5) {
						grid-column: 1 / 3;
						grid-row: 5 / 7;
						margin: 0 0 6px;
					}

					&:nth-child(6) {
						grid-column: 1 / 3;
						grid-row: 7 / 9;
					}

					a.sub-link {
						padding: 20px 35px 20px 20px;
						background: $color-white;
						transition: background 0.45s ease;
						display: flex;
						align-items: center;

						&.mod-mobile {
							display: none;

							@include mq(mobile) {
								background: transparent;
								display: block;
								height: 100%;
								left: 0;
								position: absolute;
								top: 0%;
								width: 100%;
							}
						}

						&:hover {
							background: $color-headings;

							h3 {
								color: $color-white;
							}

							.svg-icon {
								width: 68px;
								opacity: 1;
							}
						}

						h3 {
							transition: color 0.3s ease;
							font-weight: $font-weight-extrabold;
							line-height: $line-height-reset;
							text-transform: uppercase;
							margin: 0;
						}

						.svg-icon {
							padding-left: 15px;
							transition: all 0.3s ease .35s;
							width: 0;
							opacity: 0;
						}
					}
				}
			}
		}
	}
}
